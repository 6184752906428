import React, { Component } from "react";
import Card from "react-md/lib/Cards/Card";
import CardText from "react-md/lib/Cards/CardText";
import config from "../../../data/SiteConfig";
import "./Error.scss";

class Error extends Component {
  render() {
    return (
      <div className="md-grid mobile-fix">
        <Card className="md-grid md-cell--4" >
          <div className="error-wrapper">
            <div className="material-icons">error</div>
            <div className="error-code"><b>500- Something went wrong</b></div>
            <div className="error-text">
              <p>We are working on getting this fixed as soon as we can.</p>
            </div>
    
            <a className="btn btn-go-back" href="\?TradeID={tradeId}" > Go Back to Home <i class="right-arrow right"></i></a>
          </div>
        </Card>
      </div>
        
    );
  }
}
export default Error;
