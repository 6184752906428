import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout/layout";
import Error from "../components/Error/500";
import config from "../../data/SiteConfig";

class ErrorPage extends Component {

  render() {
    return (
      <Layout location={this.props.location} title="Error-layout">
        <div className="error-container">
          <Helmet>
            <title>{`500 | ${config.siteTitle}`}</title>
            <link rel="canonical" href={`${config.siteUrl}/500/`} />
          </Helmet>
          <Error />
        </div>
      </Layout>
    );
  }
}

export default ErrorPage;
